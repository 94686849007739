import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import PopUpModel from '../../components/PopUpModel';

const QRCreationForm = () => {
  const [formData, setFormData] = useState({
    inputLink: '',
    numberOfQR: '',
    name: '',
    size: '256',
    singleUse: false,
    batches: false,
    numberOfBatches: '',
    itemsPerBatch: '',
    customization: ''
  });
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const calculateEstimate = () => {
    // Add your cost calculation logic here
    return '$0.00';
  };

  const handleBack = () => {
    // Add your back navigation logic here
    console.log('Back button clicked');
  };

  return (
    <div className="min-h-screen bg-[#0f172a]">
      {/* Navigation Bar */}
      <div className="bg-white p-4 shadow-md">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <button
            onClick={handleBack}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Back</span>
          </button>
          <h1 className="text-xl font-semibold text-gray-800">Manage QR Codes</h1>
        </div>
      </div>

      {/* Form Container */}
      <div className="flex items-center justify-center p-4">
        <div className="w-full max-w-3xl bg-white rounded-lg shadow-xl p-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Fill QRcode Form</h2>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Input Link */}
            <input
              type="text"
              name="inputLink"
              value={formData.inputLink}
              onChange={handleInputChange}
              placeholder="Input Link"
              className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none text-black focus:border-gray-400"
            />

            {/* Number of QR */}
            <input
              type="number"
              name="numberOfQR"
              value={formData.numberOfQR}
              onChange={handleInputChange}
              placeholder="Number Of QR"
              className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none text-black focus:border-gray-400"
            />

            {/* Name and Editable Button */}
            <div className="flex gap-4 items-center">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Name"
                className="flex-1 p-3 border border-gray-200 rounded-lg focus:outline-none text-black focus:border-gray-400"
              />
              <button
                type="button"
                className="bg-[#1e293b] text-white px-6 py-3 rounded-lg hover:bg-[#334155] transition-colors"
              >
                Editable
              </button>
            </div>

            {/* Size */}
            <div>
              <select
                name="size"
                value={formData.size}
                onChange={handleInputChange}
                className="w-full p-3 border border-gray-300 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="256">256</option>
                <option value="512">512</option>
                <option value="740">740</option>
              </select>
            </div>

            {/* Single Use Button */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="singleUse"
                checked={formData.singleUse}
                onChange={handleInputChange}
                className="w-5 h-5 rounded border-gray-300"
              />
              <label className="text-gray-600">Single use Button</label>
            </div>

            {/* Batches Checkbox and Conditional Fields */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="batches"
                  checked={formData.batches}
                  onChange={handleInputChange}
                  className="w-5 h-5 rounded border-gray-300"
                />
                <label className="text-gray-600">Enable Batch Processing</label>
              </div>

              {formData.batches && (
                <div className="grid grid-cols-2 gap-4 pl-7">
                  <input
                    type="number"
                    name="numberOfBatches"
                    value={formData.numberOfBatches}
                    onChange={handleInputChange}
                    placeholder="Number of Batches"
                    className="p-3 border border-gray-200 rounded-lg focus:outline-none text-black focus:border-gray-400"
                  />
                  <input
                    type="number"
                    name="itemsPerBatch"
                    value={formData.itemsPerBatch}
                    onChange={handleInputChange}
                    placeholder="Items per Batch"
                    className="p-3 border border-gray-200 rounded-lg focus:outline-none text-black focus:border-gray-400"
                  />
                </div>
              )}
            </div>

            {/* Customization Options */}
            <textarea
              name="customization"
              value={formData.customization}
              onChange={handleInputChange}
              placeholder="CUSTOMIZATION OPTIONS"
              className="w-full p-3 border border-gray-200 rounded-lg h-32 text-black resize-none focus:outline-none focus:border-gray-400"
            />

            {/* Invite Member Button */}
            <button
              type="button"
              className="bg-[#1e293b] text-white px-6 py-3 rounded-lg hover:bg-[#334155] transition-colors"
            >
              Invite Member
            </button>

            {/* Empty input field */}
            <div className="flex justify-between items-center">
              <div className="border border-gray-300 rounded-md px-6 py-3">
                Estimate Cost: {calculateEstimate()}
              </div>
              
              {/* Publish Button */}
              <button
                type="submit"
                className="bg-[#1e293b] text-white px-8 py-3 rounded-lg hover:bg-[#334155] transition-colors"
                onClick={() => setShowModal(true)}
              >
                Publish
              </button>
            </div>
          </form>
          <PopUpModel 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
        </div>
      </div>
     
    </div>
  );
};

export default QRCreationForm;