import React from 'react';
import { MdDownload, MdModeEdit, MdOutlineEditOff } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { HiArchiveBoxXMark } from "react-icons/hi2"

function QrCodeCard({ qr, handleQRSelect, setEdit, setCreateQR, handleDelete,selectedQr }) {
  const navigate = useNavigate();
  const accountType = localStorage.getItem('type');
  const formattedDate = new Date(qr.createdAt).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const downloadQR = (qrImage) => {
    const link = document.createElement('a');
    const fileName = `QRCode.png`;
    link.href = qrImage; 
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const editHandler = (qr) => {
    if(accountType!=="Premium"){
      navigate('/premium')
    }
    setEdit(true);
    setCreateQR(true);
    selectedQr(qr);
  };
 
  const handleEditOff=()=>{
    navigate("/premium")
  }
  return (
    <div
      className="bg-gray-800 p-4 rounded-lg cursor-pointer hover:shadow-lg transition-all duration-300 border border-transparent hover:border-gradient-to-r hover:from-purple-500 hover:to-pink-500"
    >
      {qr.status === 'draft' && (
        <span className="absolute top-2 left-2 bg-yellow-500 text-black text-xs font-semibold px-2 py-1 rounded">
          Draft
        </span>
      )}

      <div className="flex flex-col sm:flex-row">
        <div className='w-full sm:w-1/4 mb-4 sm:mb-0 sm:mr-4'
         onClick={() => handleQRSelect(qr)}
         >
          <img src={qr.qr_image} alt="QR Code" className="w-full h-auto rounded-lg" />
        </div>

        <div className="text-white w-full sm:w-3/4 flex flex-col justify-between">
          <div className='mb-4'>
          <p className="text-sm truncate mb-2">Name: <span className=' text-lime-400 font-bold'>{qr.qr_name?(qr.qr_name).toUpperCase():qr.qr_url}</span></p>
          <p className="text-sm truncate mb-2">Your Link: <span className=' text-gray-400'>{qr.qr_url}</span></p>
            <p className="text-sm truncate mb-2">Redirect Link: <span className=' text-gray-400'>{`https://s-qc.in/api/redirect/${qr.qr_short_id}`}</span></p>
            <p className="text-sm truncate ">Created On: <span className=' text-gray-400'>{formattedDate}</span> </p>
          </div>
          
          <div className='flex flex-wrap gap-2'>
            <div className='bg-white text-black p-2 flex items-center rounded-lg cursor-not-allowed'>
              <p className='font-bold mr-2'>Scanned:</p> <p>{qr.qr_scan_count || 0}</p>
            </div>
            <button
              onClick={() => downloadQR(qr.qr_image)}
              className="bg-[#fff] text-black p-2 rounded-lg hover:bg-[#9BEC00] flex items-center"
            >
              <p className='mr-2 text-base font-bold'>Download</p> <MdDownload size={24}/>
            </button>
            <button
              className={`${
                accountType === "Premium" ? "bg-[#fff] text-black hover:bg-[#9BEC00]" : "bg-gray-300 text-gray-500 hover:bg-slate-950"
              } p-2 rounded-lg flex items-center`}
              onClick={()=>editHandler(qr)}
            >
              <p className="mr-2 text-base font-bold">Edit</p>
              {accountType === "Premium" ? (
                <MdModeEdit size={24} />
              ) : (
                <MdOutlineEditOff size={24} onClick={handleEditOff}/>
              )}
            </button>
            <button
            className="bg-white text-black p-2 rounded-lg hover:bg-[#9BEC00] flex items-center"
            onClick={()=>handleDelete(qr)}
            >
              <p className='mr-2 text-base font-bold'>Delete</p>
            <HiArchiveBoxXMark color='red'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QrCodeCard;