// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdp8l8CU6pj-JWYteXop2GQgwBmGZR5HM",
  authDomain: "smart-qrcodes.firebaseapp.com",
  projectId: "smart-qrcodes",
  storageBucket: "smart-qrcodes.appspot.com",
  messagingSenderId: "522310212786",
  appId: "1:522310212786:web:30d77a2084cf67362c3dab",
  measurementId: "G-1MXWPP0EKJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

export { signInWithGoogle, auth };