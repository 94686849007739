import React, { useState, useEffect } from 'react';
import { Link, Copy, Check, ArrowLeft, Globe } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import RestApi from '../../Apis/RestApi';

const AddSmartLinks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const editData = location.state?.Data;
  const isEditMode = !!editData;

  const initialFormState = {
    redirect_link: '',
    name: '',
    short_id: '',
    tags: [],
    metadata: {},
    settings: {
      trackingOptions: {
        captureIP: true,
        captureReferrer: true,
        captureUserAgent: true,
        captureGeolocation: true
      }
    }
  };

  const [formData, setFormData] = useState(initialFormState);
  const userId = localStorage.getItem('_id');
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (isEditMode && editData) {
      // In edit mode, populate form with existing data
      setFormData({
        ...initialFormState,
        ...editData
      });
    } else {
      // In create mode, generate new short_id
      setFormData({
        ...initialFormState,
        short_id: nanoid(8)
      });
    }
  }, [isEditMode, editData]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!formData.redirect_link) {
        setError('Destination URL is required');
        return;
      }

      setIsLoading(true);
      setError('');
      
      const data = {
        ...formData,
        user: userId
      };

      let response;
      if (isEditMode) {
        response = await RestApi.updateSmartLink(data);
      } else {
        response = await RestApi.createSmartLink(data);
      }

      if (!response.data) {
        throw new Error(`Failed to ${isEditMode ? 'update' : 'create'} smart link`);
      }

      navigate('/home/smartlink/view');
      
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full min-h-screen bg-gray-900 text-gray-100 p-6">
      <div className='max-w-4xl mx-auto mb-8'>
        <div className="flex items-center justify-between">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-white bg-gray-500 p-3 rounded-lg hover:text-white transition-colors"
          >
            <ArrowLeft size={24} className="mr-2" />
            Back
          </button>
          <h2 className='text-2xl font-bold rounded-lg  p-3'>
            {isEditMode ? 'Edit the Smart Link' : 'Create New Smart Link'}
          </h2>
        </div>

        {error && (
          <div className="mt-4 p-4 bg-red-500/20 border border-red-500 rounded-lg">
            {error}
          </div>
        )}
      </div>

      <div className="max-w-4xl mx-auto space-y-6">
        {/* Short URL Display (always shown but disabled in edit mode) */}
      {isEditMode &&  <div className="bg-gray-800 p-6 rounded-lg">
          <h2 className="text-lg font-medium flex items-center mb-4">
            <Globe size={20} className="mr-2" />
            Short URL
          </h2>
          <div className="flex items-center space-x-2">
            <div className="flex-1 flex items-center bg-gray-900 rounded-lg overflow-hidden">
              <span className="bg-transparent px-3 py-2 border-r border-gray-700 text-gray-400">
                qrc.sh/
              </span>
              <input
                type="text"
                value={formData.short_id}
                className="flex-1 bg-transparent px-3 py-2 text-gray-400 cursor-not-allowed"
                disabled
                readOnly
              />
            </div>
            <button 
              className="p-2 text-gray-400 hover:text-gray-300 transition-colors"
              onClick={() => handleCopy(`qrc.sh/${formData.short_id}`)}
            >
              {isCopied ? <Check size={20} /> : <Copy size={20} />}
            </button>
          </div>
        </div>}

        {/* Destination URL Input */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <h2 className="text-lg font-medium flex items-center mb-4">
            <Link size={20} className="mr-2" />
            Destination URL
          </h2>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              name="redirect_link"
              value={formData.redirect_link}
              onChange={handleInputChange}
              placeholder="Enter your destination URL"
              className="flex-1 bg-gray-900 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button 
              className="p-2 text-gray-400 hover:text-gray-300 transition-colors"
              onClick={() => handleCopy(formData.redirect_link)}
            >
              {isCopied ? <Check size={20} /> : <Copy size={20} />}
            </button>
          </div>
        </div>

        {/* Name Input */}
        <div className="bg-gray-800 p-6 rounded-lg">
          <h2 className="text-lg font-medium mb-4">Link Name {!isEditMode && '(Optional)'}</h2>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder={isEditMode ? "Enter link name" : "Enter a name for your link"}
            className="w-full bg-gray-900 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-end mt-8">
          <button 
            className={`px-8 py-3 bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors text-xl font-semibold ${
              isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading 
              ? (isEditMode ? 'Saving Changes...' : 'Creating...') 
              : (isEditMode ? 'Save Changes' : 'Create Link')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSmartLinks;