import React, { useState, useEffect } from 'react';
import { signInWithGoogle } from '../../firebase';
import Photo from '../../Assets/photo12.png'
import RestApi from '../../Apis/RestApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isBrowser } from 'react-device-detect';
import { publicIpv4 } from 'public-ip';


const Signup = () => {
  const [formValues, setFormValues] = useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const navigate=useNavigate();
  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);
    };

    loadGoogleScript();

    return () => {
      const script = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const initializeGoogleSignIn = () => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogleSignInResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById('googleSignUpButton'),
        { theme: 'outline', size: 'large', text: 'signup_with' }
      );
    }
  };

  const [errors, setErrors] = useState({});

  const handleGoogleSignInResponse = async (response) => {
    try {
      const { credential } = response;
      const address = `${await publicIpv4()}_${isBrowser ? "web" : "mobile"}`;

      const apiResponse = await RestApi.googleSignUp({ credential, address });

      if (apiResponse.data.status) {
        toast.success("Signed up successfully with Google");
        navigate(apiResponse.data.data.userObj.first_login===true ? '/home' : '/premium');
      } else {
        toast.error(apiResponse.data.error || "Error while signing up with Google");
      }
    } catch (error) {
      console.error("Error during Google sign-up:", error);
      toast.error("Internal Server Error, Please Try Again Later!");
    }
  };


  const validate = () => {
    let errors = {};

    if (!formValues.fullName.trim()) {
      errors.fullName = 'Full name is required';
    }

    if (!formValues.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = 'Email address is invalid';
    }

    if (!formValues.password) {
      errors.password = 'Password is required';
    } else if (formValues.password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }

    if (!formValues.confirmPassword) {
      errors.confirmPassword = 'Confirm your password';
    } else if (formValues.confirmPassword !== formValues.password) {
      errors.confirmPassword = 'Passwords do not match';
    }

    return errors;
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      try{
        let data={
          email:formValues.email,
          username:formValues.fullName,
          password:formValues.password
        }
        let response=await RestApi.signUp(data);
        if(response.data.status){
          toast.success("Successfully Signed Up")
          navigate(`/verify-email`)
        }
        else{
          toast.error("Unable to Sign Up, Please Try Again Later")
        }
      }catch(error){
        console.log(error)
        toast.error("Internal Server Error, Please Try Again Later")
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100">
      <div className="flex w-full max-w-4xl rounded-lg bg-white shadow-lg ">
        <div className="w-1/2 p-8">
          <h2 className="text-3xl font-bold mb-4">Create your account</h2>
          <p className="mb-6">Fill in the information below to create your account.</p>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.fullName}
                onChange={handleChange}
              />
              {errors.fullName && <p className="text-red-500 text-xs mt-1">{errors.fullName}</p>}
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.email}
                onChange={handleChange}
              />
              {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.password}
                onChange={handleChange}
              />
              {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm sm:leading-[2rem]"
                value={formValues.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>}
            </div>
            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Sign Up
            </button>
          </form>

          <div className="mt-6">
            <div id="googleSignUpButton"></div>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">Already have an account? <span onClick={() => navigate('/login')} className="font-medium text-indigo-600 hover:text-indigo-500">Sign in</span></p>
          </div>
        </div>
        <div className="w-1/2 bg-cover bg-center" style={{ backgroundImage: 'url(/path-to-your-image.png)' }}>
          <div className="flex h-full items-center justify-center flex-col">
          <h1 className='font-extrabold text-4xl mb-8 animated-gradient'>Smart QR Codes</h1>
            <img src={Photo} alt="Karina" className="w-4/5 object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
