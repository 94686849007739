import React from 'react';
import { useNavigate } from 'react-router-dom';

const EnterprisePage = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <div className="min-h-screen bg-gray-900 p-8">
      <h1 className="text-4xl font-bold text-white mb-12 ml-4">Enterprise</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
        {/* QR Codes Box */}
        <div 
          onClick={() => handleNavigation('/home/enterprise/qrcodes')}
          className="bg-gray-800 p-8 rounded-lg cursor-pointer hover:shadow-xl transition-all duration-300 border border-transparent hover:border-[#9BEC00] group"
        >
          <div className="h-64 bg-white rounded-lg flex items-center justify-center mb-6">
            <span className="text-3xl font-semibold text-gray-800 group-hover:text-[#9BEC00] transition-colors duration-300">
              QR Codes
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-white text-xl">Manage QR Codes</span>
            <div className="w-10 h-10 rounded-full bg-[#9BEC00] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>

        {/* Smart Links Box */}
        <div 
          onClick={() => handleNavigation('/home/enterprise/smartlink')}
          className="bg-gray-800 p-8 rounded-lg cursor-pointer hover:shadow-xl transition-all duration-300 border border-transparent hover:border-[#9BEC00] group"
        >
          <div className="h-64 bg-gray-700 rounded-lg flex items-center justify-center mb-6">
            <span className="text-3xl font-semibold text-white group-hover:text-[#9BEC00] transition-colors duration-300">
              Smart Links
            </span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-white text-xl">Manage Smart Links</span>
            <div className="w-10 h-10 rounded-full bg-[#9BEC00] flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterprisePage;