import React, { useEffect, useState, useRef } from 'react';
import RestApi from '../../Apis/RestApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import QrCodeCard from '../../components/qrComponents/QrCodeCard';
import QrCodePopUp from '../../components/qrComponents/QrCodePopUp';

const QrCodeScreen = () => {
  const [selectedQR, setSelectedQR] = useState(null);
  const [createQR, setCreateQR] = useState(false);
  const [link, setLink] = useState('');
  const userId = localStorage.getItem('_id');
  const [qrCodes, setQrCodes] = useState([]);
  const qrCodeRef = useRef(null);
  const [qrBase64, setQrBase64] = useState();
  const [edit, setEdit] = useState(false);
  const accountType = localStorage.getItem("type");
  const navigate = useNavigate();

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 3; // Set the limit of QR codes per page

  const fetchUserQr = async () => {
    const response = await RestApi.getQrByUserid({ user_id: userId });
    setQrCodes(response.data.data.slice().reverse());
  };

  useEffect(() => {
    fetchUserQr();
  }, [createQR]);

  const handleDelete = async(qr)=>{
    const response = await RestApi.deactivateQr(qr._id);
    if(response.status){
      toast.success("Successfully Deleted QR")
      fetchUserQr()
    }
    else{
      toast.error("Error Deleting QR")
    }
  }
  
  const createHandler = () => {
    setEdit(false)
    setCreateQR(true);
  };

  const handleQRSelect = (qr) => {
    navigate(`/qrcode/${qr._id}`)
  };

  const handleClose = () => {
    setSelectedQR(null);
  };

 
  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < Math.ceil(qrCodes.length / pageLimit)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  const paginatedQrCodes = qrCodes.slice((currentPage - 1) * pageLimit, currentPage * pageLimit);

  return (
    <div className="flex h-screen bg-[#0f172a] text-white overflow-hidden">
      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-shrink-0 p-4 sm:p-6 border-b border-gray-700">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">QR Codes</h2>
            <button className="bg-gradient-to-r from-blue-500 to-teal-400 text-white py-2 px-4 rounded" onClick={createHandler}>+ Create QR Code</button>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 sm:p-6">
          {createQR && (
            <QrCodePopUp setCreateQR={setCreateQR}  setEdit={setEdit} selectedQR={selectedQR} edit={edit} />
          )}

        <div className="space-y-4">
            {paginatedQrCodes && paginatedQrCodes.length > 0 ? (
              paginatedQrCodes.map((qr) => (
                <QrCodeCard key={qr._id} qr={qr} handleQRSelect={handleQRSelect} setEdit={setEdit} setCreateQR={setCreateQR} selectedQr={setSelectedQR} handleDelete={handleDelete} />
              ))
            ) : (
              <p className="text-gray-500">No QR codes available.</p>
            )}
          </div>

          {/* Pagination Controls */}
          {paginatedQrCodes && (paginatedQrCodes.length >= 2 || currentPage>1) && (
            <div className="flex justify-between items-center mt-6">
              <button
                className={`bg-gray-600 text-white hover:bg-gray-800 py-2 px-4 rounded ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="text-white">
                Page {currentPage} of {Math.ceil(qrCodes.length / pageLimit)}
              </span>
              <button
                className={`bg-gray-600 text-white hover:bg-gray-800 py-2 px-4 rounded ${currentPage === Math.ceil(qrCodes.length / pageLimit) && 'opacity-50 cursor-not-allowed'}`}
                onClick={handleNextPage}
                disabled={currentPage === Math.ceil(qrCodes.length / pageLimit)}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </div>

      {/* QR Code details sidebar */}
      {/* {selectedQR && (
        <QrDetails  qrId={selectedQR._id} />
      )} */}
    </div>
  );
};

export default QrCodeScreen;