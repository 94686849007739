import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, CartesianGrid } from 'recharts';
import { Calendar, Download, Map } from 'lucide-react';
import { useParams } from 'react-router-dom';
import RestApi from '../../Apis/RestApi';
import { Map as MapIcon } from 'lucide-react';

const QRDetailsPage = () => {
  const [scanHistory, setScanHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [activeView, setActiveView] = useState('day');
  const [currentPage, setCurrentPage] = useState(1);
  const [customDateRange, setCustomDateRange] = useState({
    from: new Date(),
    to: new Date()
  });
  const rowsPerPage = 10;
  const { id: qrId } = useParams();

  // UTC to IST conversion helper
  const getLocalDate = (timestamp) => {
    return new Date(timestamp);
  };
  
  const isSameLocalDay = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getDate() === d2.getDate() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getFullYear() === d2.getFullYear();
  };
  
  const getTodayData = () => {
    const localNow = new Date();
    const today = new Date(localNow);
    today.setHours(0, 0, 0, 0);
  
    const hourRanges = Array(24).fill(0).map((_, index) => ({
      range: `${String(index).padStart(2, '0')}:00-${String((index + 1) % 24).padStart(2, '0')}:00`,
      count: 0
    }));
  
    scanHistory.forEach(scan => {
      const scanTime = new Date(scan.timestamp);
      if (scanTime.getFullYear() === today.getFullYear() &&
          scanTime.getMonth() === today.getMonth() &&
          scanTime.getDate() === today.getDate()) {
        const hour = scanTime.getHours();
        hourRanges[hour].count++;
      }
    });
  
    return hourRanges.map(item => ({
      time: item.range,
      scans: item.count
    }));
  };
  
  const getCurrentWeekData = () => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - 6);
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(today);
    endOfWeek.setHours(23, 59, 59, 999);
  
    const days = Array(7).fill(0).map((_, index) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + index);
      return {
        date,
        day: date.toLocaleDateString(undefined, { weekday: 'short' }),
        count: 0
      };
    });
  
    scanHistory.forEach(scan => {
      const scanTime = new Date(scan.timestamp);
      if (scanTime >= startOfWeek && scanTime <= endOfWeek) {
        const dayIndex = days.findIndex(dayData => 
          scanTime.getFullYear() === dayData.date.getFullYear() &&
          scanTime.getMonth() === dayData.date.getMonth() &&
          scanTime.getDate() === dayData.date.getDate()
        );
        if (dayIndex !== -1) {
          days[dayIndex].count++;
        }
      }
    });
  
    return days.map(item => ({
      time: item.day,
      scans: item.count
    }));
  };
  
  const getCurrentMonthData = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  
    const days = Array(daysInMonth).fill(0).map((_, index) => ({
      day: index + 1,
      count: 0
    }));
  
    scanHistory.forEach(scan => {
      const scanTime = new Date(scan.timestamp);
      if (scanTime.getFullYear() === currentYear && 
          scanTime.getMonth() === currentMonth) {
        const dayOfMonth = scanTime.getDate();
        days[dayOfMonth - 1].count++;
      }
    });
  
    return days.map(item => ({
      time: item.day.toString(),
      scans: item.count
    }));
  };
  
  const getCurrentYearData = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const monthData = months.map(month => ({
      month,
      count: 0
    }));
  
    scanHistory.forEach(scan => {
      const scanTime = new Date(scan.timestamp);
      if (scanTime.getFullYear() === currentYear) {
        monthData[scanTime.getMonth()].count++;
      }
    });
  
    return monthData.map(item => ({
      time: item.month,
      scans: item.count
    }));
  };
  
  const getCustomData = () => {
    const startDate = new Date(customDateRange.from);
    const endDate = new Date(customDateRange.to);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
  
    const days = [];
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      days.push({
        date: new Date(d),
        count: 0
      });
    }
  
    scanHistory.forEach(scan => {
      const scanDate = new Date(scan.timestamp);
      const dayEntry = days.find(day => 
        isSameLocalDay(scanDate, day.date)
      );
      if (dayEntry) {
        dayEntry.count++;
      }
    });
  
    return days.map(item => ({
      time: item.date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' }),
      scans: item.count
    }));
  };


  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await RestApi.getQrById(qrId);
        const history = response.data.data.qrData.scanHistory;
        setScanHistory(history);
        if (history.length > 0) {
          setChartData(getTodayData()); // Set initial chart data
        }
      } catch (error) {
        console.error('Error fetching QR details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchDetails();
    
  }, [qrId]);


useEffect(() => {
  if (scanHistory.length > 0) {
    let newData;
    switch(activeView) {
      case 'day':
        newData = getTodayData();
        break;
      case 'week':
        newData = getCurrentWeekData();
        break;
      case 'month':
        newData = getCurrentMonthData();
        break;
      case 'year':
        newData = getCurrentYearData();
        break;
      case 'custom':
        newData = getCustomData();
        break;
      default:
        newData = getTodayData();
    }
    setChartData(newData);
  }
}, [activeView, scanHistory]);
  useEffect(() => {
    if (activeView === 'custom') {
      setChartData(getCustomData());
    }
  }, [customDateRange]);

  const processDeviceData = () => {
    const devices = {};
    scanHistory.forEach(scan => {
      const userAgent = scan.deviceInfo?.toLowerCase() || '';
      let deviceType = 'Others';
      
      if (userAgent.includes('mobile') || userAgent.includes('android') || userAgent.includes('iphone')) {
        deviceType = 'Mobile';
      } else if (userAgent.includes('tablet') || userAgent.includes('ipad')) {
        deviceType = 'Tablet';
      } else if (userAgent.includes('windows') || userAgent.includes('macintosh') || userAgent.includes('linux')) {
        deviceType = 'Desktop';
      }
      
      devices[deviceType] = (devices[deviceType] || 0) + 1;
    });

    return Object.entries(devices).map(([name, count]) => ({
      name,
      value: Math.round((count / scanHistory.length) * 100)
    }));
  };

  // Process location data for heatmap
  const processLocationData = () => {
    // Group scans by location
    const locationCounts = scanHistory.reduce((acc, scan) => {
      const { city, region, country } = scan.location || {};
      const locationKey = [city, region, country].filter(Boolean).join(', ');
      
      if (locationKey) {
        acc[locationKey] = {
          city: city || 'Unknown',
          region: region || 'Unknown',
          country: country || 'Unknown',
          count: (acc[locationKey]?.count || 0) + 1
        };
      }
      return acc;
    }, {});
  
    // Convert to array and calculate percentages
    const totalScans = scanHistory.length;
    return Object.values(locationCounts)
      .map(location => ({
        ...location,
        percentage: totalScans > 0 ? Math.round((location.count / totalScans) * 100) : 0
      }))
      .sort((a, b) => b.count - a.count); // Sort by count in descending order
  };

  const processBrowserData = () => {
    const browsers = {};
    scanHistory.forEach(scan => {
      const userAgent = scan.deviceInfo;
      let browserName = 'Others';
      if (userAgent.includes('Chrome')) browserName = 'Chrome';
      else if (userAgent.includes('Firefox')) browserName = 'Firefox';
      else if (userAgent.includes('Safari')) browserName = 'Safari';
      else if (userAgent.includes('Edge')) browserName = 'Edge';
      browsers[browserName] = (browsers[browserName] || 0) + 1;
    });
    return Object.entries(browsers).map(([name, count]) => ({
      name,
      value: Math.round((count / scanHistory.length) * 100)
    }));
  };

  const processOSData = () => {
    const os = {};
    scanHistory.forEach(scan => {
      const userAgent = scan.deviceInfo;
      let osName = 'Others';
      if (userAgent.includes('Windows')) osName = 'Windows';
      else if (userAgent.includes('Mac')) osName = 'MacOS';
      else if (userAgent.includes('Linux')) osName = 'Linux';
      else if (userAgent.includes('Android')) osName = 'Android';
      else if (userAgent.includes('iOS')) osName = 'iOS';
      os[osName] = (os[osName] || 0) + 1;
    });
    return Object.entries(os).map(([name, count]) => ({
      name,
      value: Math.round((count / scanHistory.length) * 100)
    }));
  };

  const handleViewChange = (view) => {
    setActiveView(view);
  };

  // Download CSV functionality
  const downloadCSV = () => {
    // Add quotes around fields to handle commas within fields
    const escapeField = (field) => {
      if (field === null || field === undefined) return '""';
      return `"${String(field).replace(/"/g, '""')}"`;
    };
  
    // Helper function to format device and OS into single string
    const formatDeviceAndOS = (deviceInfo) => {
      let deviceType = 'unknown';
      let os = 'unknown';
  
      if (deviceInfo) {
        // Determine device type
        if (deviceInfo.toLowerCase().includes('mobile') || 
            deviceInfo.toLowerCase().includes('android') || 
            deviceInfo.toLowerCase().includes('iphone')) {
          deviceType = 'mobile';
        } else if (deviceInfo.toLowerCase().includes('tablet') || 
                   deviceInfo.toLowerCase().includes('ipad')) {
          deviceType = 'tablet';
        } else if (deviceInfo.toLowerCase().includes('windows') || 
                   deviceInfo.toLowerCase().includes('macintosh') || 
                   deviceInfo.toLowerCase().includes('linux')) {
          deviceType = 'desktop';
        }
  
        // Determine OS
        if (deviceInfo.includes('Windows')) os = 'Windows';
        else if (deviceInfo.includes('Mac')) os = 'MacOS';
        else if (deviceInfo.includes('Linux')) os = 'Linux';
        else if (deviceInfo.includes('Android')) os = 'Android';
        else if (deviceInfo.includes('iOS')) os = 'iOS';
      }
  
      return `${deviceType} / ${os}`;
    };
  
    // Create main scan history CSV
    const scanHistoryCSV = [
      // Header row
      ['Sr. No.', 'Date & Time', 'Device / OS', 'Location', 'Browser'].map(escapeField).join(','),
      // Data rows
      ...scanHistory.map((scan, index) => [
        index + 1,
        new Date(scan.timestamp).toLocaleString(),
        formatDeviceAndOS(scan.deviceInfo),
        `${scan.location?.city || 'Unknown'}, ${scan.location?.country || 'Unknown'}`,
        scan.browser || 'Unknown'
      ].map(escapeField).join(','))
    ].join('\n');
  
    // Create location summary CSV
    const locationSummaryCSV = [
      '\n\nLocation-wise Summary',
      ['City', 'Country', 'Total Scans'].map(escapeField).join(','),
      ...Object.entries(
        scanHistory.reduce((acc, scan) => {
          const location = `${scan.location?.city || 'Unknown'}, ${scan.location?.country || 'Unknown'}`;
          acc[location] = (acc[location] || 0) + 1;
          return acc;
        }, {})
      ).map(([location, count]) => {
        const [city = 'Unknown', country = 'Unknown'] = location.split(', ');
        return [city, country, count].map(escapeField).join(',');
      })
    ].join('\n');
  
    // Create device/OS summary
    const deviceOSSummary = scanHistory.reduce((acc, scan) => {
      const deviceOS = formatDeviceAndOS(scan.deviceInfo);
      acc[deviceOS] = (acc[deviceOS] || 0) + 1;
      return acc;
    }, {});
  
    const deviceOSCSV = [
      '\n\nDevice & OS Summary',
      ['Device / OS', 'Count', 'Percentage'].map(escapeField).join(','),
      ...Object.entries(deviceOSSummary).map(([deviceOS, count]) => {
        const percentage = ((count / scanHistory.length) * 100).toFixed(2);
        return [deviceOS, count, `${percentage}%`].map(escapeField).join(',');
      })
    ].join('\n');
  
    // Combine all CSVs
    const fullCSV = scanHistoryCSV + locationSummaryCSV + deviceOSCSV;
  
    // Create and trigger download
    const blob = new Blob(['\ufeff' + fullCSV], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    link.setAttribute('download', `scan_history_${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

  if (loading) return <div className="text-white">Loading...</div>;

  return (
    <div className="p-6 space-y-6 bg-slate-950">
      <div className="bg-slate-800 rounded-lg p-4">
    <div className="flex flex-col md:flex-row justify-between gap-4">
      <div className="flex items-center gap-4">
        <button 
          onClick={() => window.history.back()}
          className="flex items-center gap-2 text-white hover:text-blue-400 transition-colors"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M10 19l-7-7m0 0l7-7m-7 7h18" 
            />
          </svg>
          <span className="font-medium">Back </span>
        </button>
      </div>

      <div className="flex flex-wrap gap-4 items-center">
        <div className="flex items-center gap-3 text-white">
          <div className="text-sm md:text-base">
            <span className="text-gray-400">Total Scans:</span>
            <span className="ml-2 font-bold">{scanHistory.length}</span>
          </div>
          <div className="h-4 w-px bg-gray-600" />
          <div className="text-sm md:text-base">
            <span className="text-gray-400">Today's Scans:</span>
            <span className="ml-2 font-bold">
              {scanHistory.filter(scan => {
                const scanDate = new Date(scan.timestamp);
                const today = new Date();
                return scanDate.getDate() === today.getDate() &&
                       scanDate.getMonth() === today.getMonth() &&
                       scanDate.getFullYear() === today.getFullYear();
              }).length}
            </span>
          </div>
        </div>
        
        <div className="flex gap-2">
          <div className="bg-blue-600/10 text-blue-400 px-3 py-1 rounded-full text-sm">
            QR ID: {qrId}
          </div>
        </div>
      </div>
    </div>
  </div>

      {/* Scan History Table */}
      <div className="bg-slate-800 rounded-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-white">Scan History</h2>
          <button 
            onClick={downloadCSV}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            <Download className="mr-2 h-4 w-4" />
            Download CSV
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-700">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase">Date</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase">Device / OS</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase">Browser</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {scanHistory.slice((currentPage - 1) * 10, currentPage * 10).map((scan, index) => (
                <tr key={index} className="text-gray-300">
                  <td className="px-6 py-4">{new Date(scan.timestamp).toLocaleString()}</td>
                  <td className="px-6 py-4">{scan.device} / {scan.os}</td>
                  <td className="px-6 py-4">{`${scan.location?.city || ''}, ${scan.location?.country || ''}`}</td>
                  <td className="px-6 py-4">{scan.browser || 'Unknown'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="px-4 py-2 bg-blue-600 text-white rounded disabled:bg-gray-600"
            >
              Previous
            </button>
            <span className="text-white">Page {currentPage}</span>
            <button
              onClick={() => setCurrentPage(prev => prev + 1)}
              disabled={currentPage * 10 >= scanHistory.length}
              className="px-4 py-2 bg-blue-600 text-white rounded disabled:bg-gray-600"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Time Based Charts */}
      <div className="bg-slate-800 rounded-lg p-4 w-full min-h-[500px]">
  <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
    <h2 className="text-2xl font-bold text-white">Scan Statistics</h2>
    <div className="flex flex-wrap gap-2">
      {['day', 'week', 'month', 'year', 'custom'].map((view) => (
        <button
          key={view}
          onClick={() => handleViewChange(view)}
          className={`px-4 py-2 rounded text-sm md:text-base ${
            activeView === view ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'
          } hover:bg-blue-500 transition-colors`}
        >
          {view.charAt(0).toUpperCase() + view.slice(1)}
        </button>
      ))}
    </div>
  </div>

  {activeView === 'custom' && (
    <div className="mb-6 flex flex-col sm:flex-row gap-4">
      <input
        type="date"
        value={customDateRange.from.toISOString().split('T')[0]}
        onChange={(e) => {
          setCustomDateRange(prev => {
            const newRange = { ...prev, from: new Date(e.target.value) };
            setChartData(getCustomData(newRange));
            return newRange;
          });
        }}
        className="bg-slate-700 text-white px-4 py-2 rounded w-full sm:w-auto"
      />
      <input
        type="date"
        value={customDateRange.to.toISOString().split('T')[0]}
        onChange={(e) => {
          setCustomDateRange(prev => {
            const newRange = { ...prev, to: new Date(e.target.value) };
            setChartData(getCustomData(newRange));
            return newRange;
          });
        }}
        className="bg-slate-700 text-white px-4 py-2 rounded w-full sm:w-auto"
      />
    </div>
  )}

  <div className="h-[400px] md:h-[500px] lg:h-[600px]">
    {chartData.length > 0 ? (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart 
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 60
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
          <XAxis 
            dataKey="time" 
            stroke="#fff"
            tick={{ fill: '#fff', fontSize: 12 }}
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
          />
          <YAxis 
            stroke="#fff"
            tick={{ fill: '#fff', fontSize: 12 }}
          />
          <Tooltip 
            contentStyle={{ 
              backgroundColor: '#1f2937', 
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              padding: '10px'
            }} 
            cursor={{ fill: 'rgba(255,255,255,0.1)' }}
            formatter={(value) => [`${value} scans`]}
          />
          <Legend 
            wrapperStyle={{
              paddingTop: '20px'
            }}
          />
          <Bar 
            dataKey="scans" 
            fill="#9BEC00"
            name="Number of Scans"
            radius={[4, 4, 0, 0]}
            maxBarSize={60}
          />
        </BarChart>
      </ResponsiveContainer>
    ) : (
      <div className="h-full flex items-center justify-center text-gray-400 text-lg">
        No data available for the selected period
      </div>
    )}
  </div>
</div>

      {/* Analytics Grid */}
        {/* OS Distribution */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* OS Distribution */}
        <div className="bg-slate-800 rounded-lg p-4">
          <h2 className="text-xl font-bold text-white mb-4">Operating Systems</h2>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={processOSData()}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label
                >
                  {processOSData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip contentStyle={{ backgroundColor: '#1f2937', color: '#fff' }} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Browser Distribution */}
        <div className="bg-slate-800 rounded-lg p-4">
          <h2 className="text-xl font-bold text-white mb-4">Browsers</h2>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={processBrowserData()}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label
                >
                  {processBrowserData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip contentStyle={{ backgroundColor: '#1f2937', color: '#fff' }} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Device Distribution - New */}
        <div className="bg-slate-800 rounded-lg p-4">
          <h2 className="text-xl font-bold text-white mb-4">Device Types</h2>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={processDeviceData()}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  label
                >
                  {processDeviceData().map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip contentStyle={{ backgroundColor: '#1f2937', color: '#fff' }} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Location Heatmap - New */}
        <div className="bg-slate-800 rounded-lg p-4">
          <h2 className="text-xl font-bold text-white mb-4">Geographic Distribution</h2>
          <div className="h-[300px] overflow-auto">
            {(() => {
              const locationData = processLocationData();
              return locationData.length > 0 ? (
                <table className="w-full text-white">
                  <thead className="sticky top-0 bg-slate-800">
                    <tr>
                      <th className="px-4 py-2 text-left">City</th>
                      <th className="px-4 py-2 text-left">Region</th>
                      <th className="px-4 py-2 text-left">Country</th>
                      <th className="px-4 py-2 text-right">Scans</th>
                      <th className="px-4 py-2 text-right">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationData.map((location, index) => (
                      <tr key={index} className="border-t border-gray-700">
                        <td className="px-4 py-2">{location.city}</td>
                        <td className="px-4 py-2">{location.region}</td>
                        <td className="px-4 py-2">{location.country}</td>
                        <td className="px-4 py-2 text-right">{location.count}</td>
                        <td className="px-4 py-2 text-right">{location.percentage}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">
                  <MapIcon className="h-12 w-12 text-gray-400 mx-auto" />
                  <p className="text-gray-400 mt-2">No location data available</p>
                </div>
              );
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRDetailsPage;