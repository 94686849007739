import React, { useEffect, useState } from 'react';
import { ExternalLink, Clock, Eye, Trash2, Edit2, BarChart2 } from 'lucide-react';
import { Button, Card } from 'flowbite-react';
import RestApi from '../../Apis/RestApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ViewSmartLink = () => {
    const [links,setLinks]=useState([]);
    const id= localStorage.getItem('_id');
    const navigate=useNavigate();
    const getSmartLinks=async()=>{
        const response= await RestApi.getSmartLinkbyUser(id)
        setLinks(response.data.data)
    }

    useEffect(()=>{
        getSmartLinks()
    },[])

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleCopyLink = (shortUrl) => {
    navigator.clipboard.writeText(`qrc.sh/${shortUrl}`);
    // You could add a toast notification here
  };

  const deleteHandler=async (id)=>{
    console.log("Delete this item:", id )
    const response=await RestApi.deleteSmartLink(id)
    if(response.status){
        toast.success("Smart Link Deleted Successfully.")
        getSmartLinks()
    }
    
  }

  const editHandler=(Data)=>{
    navigate('/home/smartlink/add', { 
        state: { Data } 
      });
  }

  const analyticsHandler=(id)=>{
    console.log("Analytics view of ID: ", id)
  }

  return (
    <div className="w-full min-h-screen bg-gray-900 text-gray-100 p-6">
      <div className="max-w-6xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-3xl font-bold">Your Smart Links</h2>
          <div className="flex gap-4">
            <select className="bg-gray-800 px-4 py-2 rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option>All Links</option>
              <option>Active</option>
              <option>Archived</option>
            </select>
            <input
              type="text"
              placeholder="Search links..."
              className="bg-gray-800 px-4 py-2 rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button className="bg-gradient-to-r from-blue-500 to-teal-400 text-white py-4 px-4 text-lg font-semibold rounded-lg" onClick={()=>navigate('/home/smartlink/add')}  >+ Create SmartLink</button>
          </div>
          
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {links.map((link) => (
            <Card 
              key={link._id} 
              className="bg-gray-800 border-gray-700"
              theme={{
                root: {
                  base: "flex rounded-lg border border-gray-700 bg-gray-800 shadow-md",
                  children: "flex h-full flex-col justify-center gap-4 p-6"
                }
              }}
            >
              <div className="flex items-center justify-between">
                <h5 className="text-base font-semibold text-white">
                  {link.name || 'Untitled Link'}
                </h5>
                <button 
                  className="p-2 hover:bg-gray-700 rounded-full transition-colors"
                  onClick={() => handleCopyLink(link.short_id)}
                >
                  <ExternalLink size={16} className="text-gray-400"  />
                </button>
              </div>

              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <span className="text-gray-400">qrc.sh/{link.short_id}</span>
                  <span className="flex items-center gap-1 text-gray-400">
                    <Clock size={14} />
                    {formatDate(link.createdAt)}
                  </span>
                </div>
                
                <div className="truncate text-sm text-gray-400">
                  {link.redirect_link}
                </div>

                <div className="flex items-center justify-between pt-2">
                  <div className="flex items-center gap-2 text-sm">
                    <div className="flex items-center gap-1 text-gray-400">
                      <Eye size={14} />
                      <span>{link.clicks || 0}</span>
                    </div>
                  </div>
                  
                  <div className="flex items-center gap-2">
                    <button className="p-2 hover:bg-gray-700 rounded-full transition-colors"
                    onClick={()=>analyticsHandler(link._id)}>
                      <BarChart2 size={16} className="text-blue-400" />
                    </button>
                    <button className="p-2 hover:bg-gray-700 rounded-full transition-colors"
                    onClick={()=>editHandler(link)}>
                      <Edit2 size={16} className="text-gray-400" />
                    </button>
                    <button className="p-2 hover:bg-gray-700 rounded-full transition-colors"
                    onClick={()=>deleteHandler(link._id)}>
                      <Trash2 size={16} className="text-red-400" />
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewSmartLink;