import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import useMediaQuery from './hooks/useMediaQuery';
import logo from './Assets/Logo_text.png';
import QrCodeScreen from './pages/Qrcode/QrCodeScreen';
import AiCodes from './pages/AiCode/AiCodes';
import Settings from './pages/Settings/Settings';
import AddSmartLinks from './pages/SmartLinks/AddSmartLink';
import ViewSmartLink from './pages/SmartLinks/ViewSmartLinks';
import EnterprisePage from './pages/Enterprise/EnterprisePage';
import QRCreationForm from './pages/Enterprise/QRCreationForm';
import SmartLinkForm from './pages/Enterprise/SmartLinkForm';

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className="flex bg-[#0f172a] text-white min-h-screen">
      <div 
        className={`${
          isMobile 
            ? 'fixed inset-y-0 left-0 z-50 transition-transform duration-300 ease-in-out transform' 
            : ''
        } ${
          isMobile && !isSidebarOpen ? '-translate-x-full' : 'translate-x-0'
        }`}
      >
        {(isSidebarOpen || !isMobile) && (
          <Sidebar 
            closeSidebar={() => setIsSidebarOpen(false)}
            isMobile={isMobile}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        )}
      </div>
      <div className="flex-1 overflow-y-auto">
        {isMobile && (
          <div className="flex justify-between items-center">
            <button 
              onClick={() => setIsSidebarOpen(!isSidebarOpen)} 
              className="text-2xl m-4"
            >
              ☰
            </button>
            <img src={logo} alt="Logo" className="h-10 w-40 mx-4"/>
          </div>
        )}
        <Routes>
          <Route path="/" element={<QrCodeScreen />} />
          <Route path="aicodes" element={<AiCodes />} />
          <Route path="smartlink/add" element={<AddSmartLinks />} />
          <Route path="smartlink/view" element={<ViewSmartLink />} />
          <Route path="enterprise" element={<EnterprisePage />} />
          <Route path="enterprise/qrcodes" element={<QRCreationForm />} />
          <Route path="enterprise/smartlink" element={<SmartLinkForm />} />
          <Route path="settings" element={<Settings />} />
        </Routes>
      </div>
      {isMobile && isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default App;