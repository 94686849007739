import http from "./http-common";

class RestApi {
  // User APIs
  validateAddress(data) {
    return http.post("/validateAddress", data);
  }
  signin(data) {
    return http.post("/signin", data);
  }
  googleSignin(data) {
    return http.post("/googleSignin", data);
  }
  changePassword(data) {
    return http.post("/changePassword", data);
  }
  signUp(data){
    return http.post("/signup", data);
  }
  googleSignUp(data){
    return http.post("/googleSignup", data);
  }
  verifyEmail(token){
    return http.get(`/verify-email?token=${token}`);
  }
  resetPasswordRequest(data){
    return http.post("/resetPasswordRequest", data);
  }
  resetPassword(data){
    return http.post(`/reset-password`,data);
  }
  resetPasswordToken(token){
    return http.get(`/reset-password?token=${token}`)
  }
  updateUser(data){
    return http.post('/updateUser', data)
  }
  // #region QR code api
  createQR(data){
    return http.post("/createQr", data);
  }
  getUrlLink(data){
    return http.get(`/redirect/${data.qr_short_id}`)
  }
  updateQr(data){
    return http.put("/updateQr", data);
  }
  getQrById(qrId){
    return http.get(`/fetchQrById/${qrId}`);
  }
  getQrByUserid(data){
    return http.post("/fetchQrByUserid", data);
  }
  //#region Premium
  initiateSubscription(data) {
    // Transform the data to match backend expectations
    const subscriptionData = {
      user_id: data.user_id,
      user_email: data.user_email,
      plan_type: this.convertPlanType(data.plan_selected),
      duration_months: this.extractDuration(data.plan_selected),
      amount: data.amount,
      currency: data.currency
    };
    return http.post("/subscription/initiate", subscriptionData);
  }

  verifyAndActivateSubscription(data) {
    return http.post("/subscription/verify", {
      order_id: data.order_id,
      payment_id: data.payment_id,
      signature: data.signature,
      user_id: localStorage.getItem("_id")
    });
  }

  // Helper methods for plan conversion
  convertPlanType(planSelected) {
    if (planSelected === "Enterprise") return "ENTERPRISE";
    if (planSelected === "Free Trial") return "FREE";
    return "PREMIUM";
  }

  extractDuration(planSelected) {
    const months = parseInt(planSelected);
    return isNaN(months) ? (planSelected === "Enterprise" ? 12 : 1) : months;
  }

  // Legacy method names for backward compatibility
  buyPremium(data) {
    return this.initiateSubscription(data);
  }

  verifyPaymentStatus(data) {
    return this.verifyAndActivateSubscription(data);
  }

  updatePaymentStatus(data) {
    return http.post("/updatePayment", data);
  }

  cancelSubscription(data) {
    return http.post("/subscription/cancel", data);
  }

  getSubscriptionDetails(userId) {
    return http.get(`/subscription/${userId}`);
  }

  checkSubscriptionStatus(userId) {
    return http.get(`/subscription/status/check?user_id=${userId}`);
  }


  buyPremium(data) {
    return this.initiateSubscription(data);
  }

  verifyPaymentStatus(data) {
    return this.verifyAndActivateSubscription(data);
  }

  updatePaymentStatus(data) {
    return http.post("/updatePayment", data);
  }
  deactivateQr(id){
    return http.post(`/deleteQr/${id}`)
  }

  // #region Smart Link

  createSmartLink(data){
    return http.post('/createSmartLink', data);
  }
  redirectSmartLink(id){
    return http.get(`/r/${id}`)
  }
  getSmartLinkbyId(id){
    return http.get(`/fetchSmartLinkById/${id}`)
  }
  getSmartLinkbyUser(id){
    return http.post(`/fetchSmartLinkByUser/${id}`)
  }
  updateSmartLink(data){
    return http.post(`/updateSmartLink/${data.id}`, data)
  }
  deleteSmartLink(id){
    return http.delete(`/deleteSmartLink/${id}`)
  }
  
}

export default new RestApi();
