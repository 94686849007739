import React, { useEffect, useState } from 'react'
import RestApi from '../Apis/RestApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


function ChangePassword() {
  const location=useLocation();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError]=useState('');
    const userid=localStorage.getItem("_id") || location.state?.user_id; 
    const [urlState, setUrlState]=useState(false)
    const navigate=useNavigate();
    
    useEffect(()=>{
      if (window.location.href.includes("change-password")) {
        setUrlState(true);
      } else {
        setUrlState(false);
      }
    },[])


    const handleChangePassword = async () => {
        if (newPassword.length < 6) {
            toast.error('Password must be at least 6 characters');
            return;
          }
        if(newConfirmPassword!==newPassword){
            toast.error('Password and Confirm Password must be same');
            return;
        }
        if(newConfirmPassword===newPassword){
            let data1={
                old_password:oldPassword,
                _id:userid,
                new_password:newPassword,
            }
            let data2={
              newpassword:newPassword,
              user_id:userid
            }
            try{
              const response=urlState?await RestApi.resetPassword(data2):await RestApi.changePassword(data1)
              if(response.data.status){
                toast.success(response.data.msg)
                urlState && navigate('/login')
              }
              else{
                toast.error("Unable to reset password")
              }
              
            }catch(error){
              if(error.response.data.status===false){
                setError(error.response.data.msg)
                toast.error("Internal Server Error", error.response.data.msg)
              }
              console.error(error)
            }
            
        }
        // try {
        //   const res = await axios.post('/api/auth/change-password', { oldPassword, newPassword, userId: localStorage.getItem('userId') });
        //   setMessage(res.data.message);
        // } catch (error) {
        //   setMessage(error.response.data.message);
        // }
      };

  
  return (
    <div className={`${urlState? ' flex justify-center items-center bg-gray-700 max-w-full h-screen' : 'container mx-auto'}`}>
         <div className="max-w-sm mx-auto mt-10">
        <h2 className="text-2xl font-bold mb-5 ">Change Password</h2>
       <div className='bg-gray-600 p-4 rounded-lg'>
       {urlState?<></>:<input
          type="password"
          placeholder="Old Password"
          className="border p-2 w-full mb-4 text-black rounded-md"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />}
        <input
          type="password"
          placeholder="New Password"
          className="border p-2 w-full mb-4 text-black rounded-md"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          className="border p-2 w-full mb-4 text-black rounded-md"
          value={newConfirmPassword}
          onChange={(e) => setNewConfirmPassword(e.target.value)}
        />
        <button
          className="bg-[#126f97] text-white p-2 w-full rounded-md"
          onClick={handleChangePassword}
        >
          Change Password
        </button>
       </div>
      </div>
    </div>
  )
}

export default ChangePassword