import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';

const SmartLinkForm = () => {
  const [formData, setFormData] = useState({
    inputLink: '',
    numberOfCharacter: '16',
    name: '',
    fileType: 'csv',
    singleUse: false,
    batches: false,
    numberOfBatches: '',
    itemsPerBatch: '',
    customization: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Smart Link Form Data:', formData);
  };

  const handleBack = () => {
    // Add your back navigation logic here
    console.log('Back button clicked');
  };

  return (
    <div className="min-h-screen bg-[#0f172a]">
      {/* Navigation Bar */}
      <div className="bg-white p-4 shadow-md">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <button
            onClick={handleBack}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 transition-colors"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Back</span>
          </button>
          <h1 className="text-xl font-semibold text-gray-800">Manage Smart Links</h1>
        </div>
      </div>

      {/* Form Container */}
      <div className="flex items-center justify-center p-4">
        <div className="w-full max-w-xl bg-white rounded-lg shadow-xl p-8">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Smart Link Form</h2>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Input Link */}
            <div>
              <input
                type="text"
                name="inputLink"
                value={formData.inputLink}
                onChange={handleInputChange}
                placeholder="Input Link"
                className="w-full p-3 border-2 border-gray-300 rounded text-black focus:outline-none focus:border-gray-400 text-center"
              />
            </div>

            {/* Number of character */}
            <div>
              <select
                name="numberOfCharacter"
                value={formData.numberOfCharacter}
                onChange={handleInputChange}
                className="w-full p-3 border-2 border-gray-300 rounded text-black focus:outline-none focus:border-gray-400"
              >
                <option value="16">16 characters</option>
                <option value="32">32 characters</option>
                <option value="48">48 characters</option>
              </select>
            </div>

            {/* Name and Editable Button */}
            <div className="flex gap-4 items-center">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Name"
                className="flex-1 p-3 border-2 border-gray-300 rounded text-black focus:outline-none focus:border-gray-400 text-center"
              />
              <button
                type="button"
                className="bg-gray-800 text-white px-6 py-3 rounded hover:bg-gray-700 transition-colors"
              >
                Editable
              </button>
            </div>

            {/* File Type Selection */}
            <div>
              <select
                name="fileType"
                value={formData.fileType}
                onChange={handleInputChange}
                className="w-full p-3 border-2 border-gray-300 rounded text-black focus:outline-none focus:border-gray-400"
              >
                <option value="csv">CSV</option>
                <option value="xls">XLS</option>
                <option value="xlsx">XLSX</option>
              </select>
            </div>

            {/* Single Use Button */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                name="singleUse"
                checked={formData.singleUse}
                onChange={handleInputChange}
                className="w-5 h-5 rounded border-gray-300"
              />
              <label className="text-gray-600">Single use Button</label>
            </div>

            {/* Batches Checkbox and Conditional Fields */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="batches"
                  checked={formData.batches}
                  onChange={handleInputChange}
                  className="w-5 h-5 rounded border-gray-300"
                />
                <label className="text-gray-600">Enable Batch Processing</label>
              </div>

              {formData.batches && (
                <div className="grid grid-cols-2 gap-4 pl-7">
                  <input
                    type="number"
                    name="numberOfBatches"
                    value={formData.numberOfBatches}
                    onChange={handleInputChange}
                    placeholder="Number of Batches"
                    className="p-3 border-2 border-gray-300 rounded text-black focus:outline-none focus:border-gray-400 text-center"
                  />
                  <input
                    type="number"
                    name="itemsPerBatch"
                    value={formData.itemsPerBatch}
                    onChange={handleInputChange}
                    placeholder="Items per Batch"
                    className="p-3 border-2 border-gray-300 rounded text-black focus:outline-none focus:border-gray-400 text-center"
                  />
                </div>
              )}
            </div>

            {/* Customization Options */}
            <div>
              <textarea
                name="customization"
                value={formData.customization}
                onChange={handleInputChange}
                placeholder="CUSTOMIZATION OPTIONS"
                className="w-full p-3 border-2 border-gray-300 rounded h-32 resize-none focus:outline-none text-black focus:border-gray-400 text-center"
              />
            </div>

            {/* Bottom Section */}
            <div className="flex justify-between items-center mt-6">
              {/* Invite Member Button */}
              <button
                type="button"
                className="bg-gray-800 text-white px-6 py-3 rounded hover:bg-gray-700 transition-colors"
              >
                Invite Member
              </button>

              <div className="flex items-center gap-4">
                {/* Estimate Cost */}
                <div className="border-2 border-gray-300 rounded px-6 py-3 text-black">
                  Estimate Cost:
                </div>

                {/* Publish Button */}
                <button
                  type="submit"
                  className="bg-gray-800 text-white px-8 py-3 rounded hover:bg-gray-700 transition-colors"
                >
                  Publish
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SmartLinkForm;