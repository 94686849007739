import React from 'react';
import { Modal } from 'flowbite-react';

const PopUpModel = ({ isOpen, onClose }) => {
  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      position="center"
      popup
    >
      <Modal.Body className="rounded-[40px] p-8">
        <div className="space-y-6">
          <h3 className="text-xl font-medium text-center">
            Pop Up modal for creation details
          </h3>
          
          <div className="space-y-4">
            <p className="text-lg text-center">
              Creation Time taken :
            </p>
            
            <p className="text-base text-center">
              Once the task is completed, email will be sent to you and the selected users.
            </p>
            
            <p className="text-base text-center">
              Also you can check on the website in its pages
            </p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopUpModel;