import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RestApi from "../../Apis/RestApi";
import { toast } from "react-toastify";
import qrOne from '../../Assets/QRCode (10).png';
import qrTwo from '../../Assets/QRCode (11).png';
import qrThird from '../../Assets/QRCode (12).png';
import qrFour from '../../Assets/QRCode (6).png';
import qrFive from '../../Assets/QRCode (7).png';
import qrSix from '../../Assets/QRCode (8).png';
import qrSeven from '../../Assets/QRCode (9).png';
import qrEight from '../../Assets/QRCode (13).png';

function ProUpgradeCard() {
  const navigate = useNavigate(); // React Router navigate hook
  const user_id = localStorage.getItem("_id");
  const user_email = localStorage.getItem("email");
  // State to track selected subscription
  const [selectedOption, setSelectedOption] = useState("12 months");
  const [amount, setAmount] = useState("7188");
  const first_login = localStorage.getItem("first_login") === "true";
  const username = localStorage.getItem("username");


  // Handle the selection
  const handleSelection = (option) => {

    setSelectedOption(option);
    handleAmount(option);
  };

  const imgArray=[
    {name:qrOne},
    {name:qrTwo},
    {name:qrThird},
    {name:qrFour},
    {name:qrFive},
    {name:qrSix},
    {name:qrSeven},
    {name:qrEight}
  ]

  
  const handleAmount = (option) => {
    let amt = 0;
    switch(option) {
      case "12 months":
        amt = 12 * 599; // 7,188 yearly
        break;
      case "6 months":
        amt = 6 * 799; // 4,794 half-yearly
        break;
      case "3 months":
        amt = 3 * 899; // 2,697 quarterly
        break;
      case "Free Trial":
        amt = 0;
        break;
      case "Enterprise":
        amt = null;
        break;
      default:
        amt = 599;
    }
    setAmount(amt);
  };

  const handleContinue = async () => {
    try {
      if (amount === 0) {
        // Handle free trial
        const data = {
          user_id,
          user_email,
          plan_selected: "Free Trial",
          amount: 0,
          currency: "INR"
        };
        await RestApi.initiateSubscription(data);
        navigate("/home");
      } else if (amount === null) {
        // Handle enterprise
        toast.success("Please check Settings Screen for contact info. Thank You");
        navigate("/home/settings");
      } else {
        // Handle paid subscriptions
        const data = {
          user_id,
          user_email,
          amount,
          plan_selected: selectedOption,
          currency: "INR",
        };

        const orderData = await RestApi.initiateSubscription(data);
        const { id: order_id, amount: order_amount } = orderData.data.data.paymentObj;
        console.log(orderData,"...oRderData:.......")
        const options = {
          key: process.env.RAZORPAY_ID,
          amount: order_amount,
          currency: "INR",
          name: "Smart QR Codes",
          description: `${selectedOption} Subscription`,
          order_id: order_id,
          handler: async (response) => {
            console.log('Razorpay payment success response:', {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              complete_response: response
            });
            const signature = response.razorpay_signature || 'TEST_SIGNATURE';
            try {
              const verifyResponse = await RestApi.verifyAndActivateSubscription({
                order_id,
                payment_id: response.razorpay_payment_id,
                signature: signature,
                user_id
              });

              if (verifyResponse.status === 200) {
                toast.success("Subscription activated successfully");
                navigate("/home");
              } else {
                toast.error("Subscription verification failed");
              }
            } catch (error) {
              toast.error("Failed to verify subscription");
              console.error(error);
            }
          },
          prefill: {
            name: username,
            email: user_email,
            contact: "**********",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }

      // Update first login status
      if (!first_login) {
        const updateData = {
          id: user_id,
          update_obj: { first_login: true }
        };
        const response = await RestApi.updateUser(updateData);
        if (response.status) {
          toast.success("Welcome To Smart QR Codes");
        }
      }
    } catch (error) {
      toast.error("Failed to process subscription");
      console.error(error);
    }
  };

  const paymentUpdateHandler = async (data) => {
    try {
      await RestApi.updatePaymentStatus(data);
    } catch (error) {
      console.log(error, "internal server error");
      toast.error("Internal Server Error")
    }
  };

  // Handle the close action (navigate to the previous page)
  const handleClose = () => {
    navigate("/home"); // Go back to the previous page
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <div className="relative bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-4xl animate-float">
        {/* Close Button */}
        {first_login && (
          <button
            className="absolute top-4 right-4 text-white bg-gray-700 hover:bg-gray-600 p-2 rounded-full"
            onClick={handleClose}
          >
            {/* Cross Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}

        <div className="grid grid-cols-4 gap-2 mb-6">
          {/* QR Code Samples */}
          {imgArray.map((img, index) => (
            <div key={index} className="h-30 w-full bg-gray-600">
              <img src={img.name} alt="samples"/>
            </div>
          ))}
        </div>
        <h1 className="text-2xl font-bold mb-4">Upgrade to Pro</h1>
        <ul className="mb-6 space-y-2">
          {selectedOption === "Free Trial" ? (
            <>
              <li>✅ Limited QR Codes</li>
              <li>✅ Watermark Applied</li>
              <li>❌ No Editable Option</li>
              <li>❌ Limited Support</li>
            </>
          ) : (
            <>
              <li>✅ Unlimited QR Codes</li>
              <li>✅ No Watermark</li>
              <li>✅ Build Dynamic Qr Codes</li>
              <li>✅ Editable Option</li>
              <li>✅ Priority Support</li>
            </>
          )}
        </ul>
        <div>
          {/* Free Trial Option */}
          <div
            className={`flex items-center justify-between space-x-2 mb-6 p-4 rounded-lg cursor-pointer ${
              selectedOption === "Free Trial" ? "bg-[#620984]" : ""
            }`}
            onClick={() => handleSelection("Free Trial")}
          >
            <div className="text-lg font-semibold">Free Trial</div>
            <div className="text-lg font-semibold">Free</div>
          </div>

          {/* 12 Months Option */}
          <div
            className={`mb-6 p-4 rounded-lg cursor-pointer ${
              selectedOption === "12 months" ? "bg-[#620984]" : ""
            }`}
            onClick={() => handleSelection("12 months")}
          >
            <div className="flex items-center justify-between">
              <div className="text-lg font-semibold">12 months</div>
              <div className="text-lg font-semibold">599/month</div>
            </div>
          </div>

          {/* 6 Months Option */}
          <div
            className={`flex items-center justify-between space-x-2 mb-6 p-4 rounded-lg cursor-pointer ${
              selectedOption === "6 months" ? "bg-[#620984]" : ""
            }`}
            onClick={() => handleSelection("6 months")}
          >
            <div className="text-lg font-semibold">6 months</div>
            <div className="text-lg font-semibold">799/month</div>
          </div>

          {/* 3 Months Option */}
          <div
            className={`flex items-center justify-between space-x-2 p-4 rounded-lg cursor-pointer ${
              selectedOption === "3 months" ? "bg-[#620984]" : ""
            }`}
            onClick={() => handleSelection("3 months")}
          >
            <div className="text-lg font-semibold">3 months</div>
            <div className="text-lg font-semibold">899/month</div>
          </div>
          <div
            className={`flex items-center justify-between space-x-2 p-4 rounded-lg cursor-pointer ${
              selectedOption === "Enterprise" ? "bg-[#620984]" : ""
            }`}
            onClick={() => handleSelection("Enterprise")}
          >
            <div className="text-lg font-semibold">Enterprise</div>
            <div className="text-lg font-semibold">Contact Sales</div>
          </div>

          {/* Continue Button */}
          <button
            className="mt-6 w-full bg-[#9423bb] py-2 rounded text-lg font-bold"
            onClick={handleContinue}
          >
            CONTINUE
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProUpgradeCard;
