import React from 'react';
import logo from '../../Assets/Logo_text.png';

const PrivacyPolicy = () => {
  const sections = [
    {
      title: "1. Information We Collect",
      content: "We may collect personal information in various ways when you use the Smart QR Codes platform. This includes:\n• Personal Information: Such as your name, email address, and payment details when you register for an account, subscribe to a service, or contact our support team.\n• Usage Data: Information about your device, browsing actions, and patterns, such as IP address, browser type, access times, and pages viewed.\n• QR Code Data: Content you input to create QR codes, and scan analytics, including the number of scans, location, and date."
    },
    {
      title: "2. How We Use Your Information",
      content: "We use the information we collect for various purposes, including:\n• To provide, maintain, and improve the Smart QR Codes platform.\n• To communicate with you, including responding to your inquiries and providing customer support.\n• To send administrative information, updates, and promotional content.\n• To process your transactions and manage your subscriptions.\n• To analyze trends, monitor the usage of our services, and improve our platform."
    },
    {
      title: "3. How We Share Your Information",
      content: "We may share your information in the following circumstances:\n• With Service Providers: We may share your information with third-party vendors and service providers who perform services on our behalf, such as payment processing, data analysis, and customer service.\n• For Legal Requirements: We may disclose your information if required to do so by law, in response to valid requests by public authorities (e.g., a court or a government agency), or to protect our rights and the security of our users.\n• Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity."
    },
    {
        title: "4. Cookies and Tracking Technologies",
        content: "We use cookies and similar tracking technologies to collect and track information and improve and analyze our services. You can instruct your browser to refuse all cookies or indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our platform"
      },
    {
        title: "5.Data Security",
        content: "We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no internet or email transmission is ever fully secure or error-free, so please take care when disclosing any personal information."
      },
      {
        title: "6. Data Retention",
        content: "We will retain your information for as long as your account is active or as needed to provide you with services, comply with our legal obligations, resolve disputes, and enforce our agreements."
      },
      {
        title: "7.  Your Rights and Choices",
        content: "You have the right to access, update, or delete your personal information at any time. If you wish to do so, please contact us at [support@smart-qrcodes.com]. We may retain certain information as required by law or for legitimate business purposes."
      },
      {
        title: "8. Children's Privacy",
        content: "Our services are not directed to children under 18, and we do not knowingly collect personal information from children under 18. If you believe we have collected information from a child under 18, please contact us so we can take appropriate steps to delete the information."
      },
      {
        title: "9. Changes to This Privacy Policy",
        content: "We may update this Privacy Policy from time to time to reflect changes in our practices, technology, legal requirements, or other factors. We encourage you to review this Privacy Policy periodically. Your continued use of the platform after any changes indicates your acceptance of the updated policy."
      },
  ];

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 bg-gray-800 shadow-lg rounded-lg">
      <div className='flex justify-between'>
     <div>
     <h1 className="text-3xl font-bold mb-6 text-white">Privacy Policy</h1>
      <p className="mb-4 text-sm text-white">
        Effective Date: 11-10-2024<br />
        Last Updated: 11-10-2024
      </p>
     </div>
     <img src={logo}  alt='Smart Qr Codes' className='max-w-40 h-10'/>
      </div>
      <p className="mb-6 text-white">
        Navneet Enterprise (doing business as "Web Dimension") is committed to protecting your
        privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your
        information when you visit our website, Smart QR Codes, accessible at
        www.smart-qrcodes.com. Please read this policy carefully. If you do not agree with the
        terms of this Privacy Policy, please do not use the site or our services.
      </p>
      {sections.map((section, index) => (
        <div key={index} className="mb-6">
          <h2 className="text-xl font-semibold mb-2 text-white">{section.title}</h2>
          <p className="text-white whitespace-pre-line">{section.content}</p>
        </div>
      ))}
      <div className="mt-8 pt-4 border-t border-gray-200">
        <h2 className="text-xl font-semibold mb-2 text-white">10. Contact Us</h2>
        <p className="text-white">
          If you have any questions about this Privacy Policy, please contact us at:<br />
          Email: support@smart-qrcodes.com<br />
          Address: E-1007, Prateek laurel, Sector-120, Noida
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;